import { render, staticRenderFns } from "./buyForget2.vue?vue&type=template&id=7b08c2c9&scoped=true&"
import script from "./buyForget2.vue?vue&type=script&lang=js&"
export * from "./buyForget2.vue?vue&type=script&lang=js&"
import style0 from "./buyForget2.vue?vue&type=style&index=0&id=7b08c2c9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b08c2c9",
  null
  
)

export default component.exports